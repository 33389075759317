import { User, UserLevel } from 'src/core/User/domain/User'
import { FC } from 'react'
import { TextColor, Text } from 'src/ui/components'
import styles from './Avatar.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Color } from 'src/ui/styles/settings'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'

interface Props {
  user: User
  showCloseIcon?: boolean
}

export const Avatar: FC<Props> = ({ user, showCloseIcon = false }) => {
  const stylesMap: {
    [key in UserLevel]: {
      backgroundColor: string
      textColor: TextColor
      iconColor: Color
    }
  } = {
    [UserLevel.NotFound]: {
      backgroundColor: 'var(--b-color-myBarcelo-start)',
      textColor: 'dark',
      iconColor: 'icon-dark',
    },
    [UserLevel.Start]: {
      backgroundColor: 'var(--b-color-myBarcelo-start)',
      textColor: 'dark',
      iconColor: 'icon-dark',
    },
    [UserLevel.Intense]: {
      backgroundColor: 'var(--b-color-myBarcelo-intense)',
      textColor: 'light',
      iconColor: 'icon-light',
    },
    [UserLevel.Unique]: {
      backgroundColor: 'var(--b-color-myBarcelo-unique)',
      textColor: 'light',
      iconColor: 'icon-light',
    },
  }

  const nameAndLastnameInitials = `${user.givenName[0].toUpperCase()}${user.familyName[0].toUpperCase()}`

  return (
    <div
      className={styles.avatar}
      style={{
        backgroundColor: stylesMap[user.level].backgroundColor,
      }}
    >
      <Text
        fontStyle="s-700"
        color={stylesMap[user.level].textColor}
        className={styles.initials}
      >
        {showCloseIcon ? (
          <Icon
            size="l"
            color={stylesMap[user.level].iconColor}
            icon={CloseIcon}
          />
        ) : (
          nameAndLastnameInitials
        )}
      </Text>
    </div>
  )
}
