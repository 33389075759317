import { FC, useRef, useState } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components/atoms/Text'

import styles from './LanguageButton.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { LanguageDropdown } from './LanguageDropdown'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'

interface Props {
  onSelectLanguage: (cbeLanguage: string) => void
}

export const LanguageButton: FC<Props> = ({ onSelectLanguage }) => {
  const { trans } = useTrans(['common'])

  const [showDropdown, setShowDropdown] = useState(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)

  const languageConfig = useLanguageConfig()

  const handleOnLanguageButtonClick = () => {
    setShowDropdown(prev => !prev)
  }

  const handleOnOutsideDropdownClick = () => {
    setShowDropdown(false)
  }

  const handleSelectLanguage = (cbeLanguage: string) => {
    handleOnOutsideDropdownClick()
    onSelectLanguage(cbeLanguage)
  }

  return (
    <div className={styles.container}>
      <div>
        <button
          className={styles.button}
          onClick={handleOnLanguageButtonClick}
          ref={triggerDropdownRef}
          aria-label={trans('header_change_language')}
          data-quantum-language-code={languageConfig.language}
        >
          <Flex gap="xs">
            <Flex gap="xs">
              <Text fontStyle="m-300" color="light">
                {languageConfig.properties.description}
              </Text>
            </Flex>
            <Icon
              icon={ChevronIcon}
              size="l"
              color="icon-light"
              rotate={showDropdown ? 270 : 90}
            />
          </Flex>
        </button>
      </div>
      <LanguageDropdown
        show={showDropdown}
        triggerRef={triggerDropdownRef}
        onClickOutside={handleOnOutsideDropdownClick}
        onSelectLanguage={handleSelectLanguage}
      />
    </div>
  )
}
