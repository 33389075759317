import { Modal, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { currencyFormat } from 'src/ui/views/_components/Shared/currencyFormat'
import styles from './CurrencyModal.module.scss'
import { createModal } from 'src/ui/hooks/useModal'
import { Media } from 'src/ui/styles/objects/Media'

interface Props {
  currencies: string[]
  selectedCurrency: string
  onSelectCurrency: (currency: string) => void
}

export const CurrencyModal = createModal(
  ({ currencies, selectedCurrency, onSelectCurrency }: Props) => {
    const { trans } = useTrans(['common'])

    return (
      <Media mobile tablet>
        <Modal size="m" type="passive" mobileStyle="fullscreen">
          <Text fontStyle="xl-700" color="dark">
            {trans('header_change_currency_title')}
          </Text>
          <ul className={styles.container}>
            {currencies.map(currency => (
              <li key={currency}>
                <button
                  className={styles.button}
                  onClick={() => onSelectCurrency(currency)}
                >
                  <Text
                    fontStyle={
                      currencyFormat.getIsoCode(currency) === selectedCurrency
                        ? 's-700'
                        : 's-300'
                    }
                    color="dark"
                  >
                    {currency}
                  </Text>
                </button>
              </li>
            ))}
          </ul>
        </Modal>
      </Media>
    )
  },
)
