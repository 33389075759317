import styles from './CampaignModal.module.scss'
import { createModal } from 'src/ui/hooks/useModal'
import { Modal, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Campaign } from 'src/core/Hotel/domain/Campaign.model'
import { classNames } from 'src/ui/utils/classnames'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'

interface Props {
  campaign: Campaign
}

export const CampaignModal = createModal(({ campaign }: Props) => {
  const { trans } = useTrans(['new-reservation'])

  const formatDate = (date: Date) => {
    return datesManager.formatDateToLocale(date, 'long', 'long')
  }

  return (
    <Modal
      type="transactional"
      size="l"
      ariaLabel={campaign.title}
      data-quantum-modal="CampaignModal"
    >
      <Flex direction="column">
        <Text fontStyle="xl-700" color="dark" className={styles.title}>
          {campaign.title}
        </Text>
        <Flex direction="column" gap="m">
          <Text fontStyle={{ mobile: 'm-500', laptop: 'l-700' }} color="dark">
            {campaign.head}
          </Text>
          <ul className={styles.list}>
            <li className={classNames(styles.listElement)}>
              {trans('available-rooms_generic-campaign-modal_discount-info', {
                discount: campaign.discount,
              })}
            </li>
            {campaign.featuredArguments.map(arg => (
              <li className={styles.listElement} key={arg}>
                {arg}
              </li>
            ))}
          </ul>
          <Text fontStyle="m-300" color="dark" className={styles.datesInfo}>
            {trans('available-rooms_generic-campaign-modal_dates-info', {
              bookingStart: formatDate(campaign.bookingStart),
              bookingEnd: formatDate(campaign.bookingEnd),
              travelStart: formatDate(campaign.travelStart),
              travelEnd: formatDate(campaign.travelEnd),
            })}
          </Text>
        </Flex>
      </Flex>
    </Modal>
  )
})
