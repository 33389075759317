import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useAsyncMutation } from 'src/ui/hooks/useMutation'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CouponValidationType } from 'src/core/Hotel/domain/CouponValidation.model'

export const useCouponValidator = () => {
  const { queryUtils } = useApplicationRouter()
  const hotelId = queryUtils.getRawParam('hotel')
  const checkIn = queryUtils.getRawParam('arrive')
  const checkOut = queryUtils.getRawParam('depart')

  // Esto realmente no es una mutación pero es la forma más sencilla de lanzar una petición de momento
  // si este tipo de casos se repiten buscaremos una solución más apropiada
  const { performMutation: validateCoupon } = useAsyncMutation(
    'validateCoupon',
    async (couponId: string) => {
      if (
        isUndefined(hotelId) ||
        isUndefined(checkIn) ||
        isUndefined(checkOut)
      ) {
        return { type: CouponValidationType.INVALID }
      }

      return container.resolve('getCouponValidation')(
        couponId,
        hotelId,
        checkIn,
        checkOut,
      )
    },
  )

  return {
    validateCoupon,
  }
}
