import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Time } from 'src/core/Shared/infrastructure/Time'

export const useCampaign = () => {
  const { queryUtils } = useApplicationRouter()
  const {
    properties: { cmsLanguage },
  } = useLanguageConfig()

  const hotelId = queryUtils.getRawParam('hotel') ?? ''
  const checkIn = queryUtils.getRawParam('arrive')
  const checkOut = queryUtils.getRawParam('depart')
  const marketprice = queryUtils.getRawParam('marketprice') ?? ''
  const promotionalCoupon = queryUtils.getCouponParam() ?? ''

  const now = Time.now().toDate()
  const checkInFormatted = isDefined(checkIn)
    ? Time.fromString(checkIn).toDate()
    : undefined
  const checkOutFormatted = isDefined(checkOut)
    ? Time.fromString(checkOut).subtract(1, 'day').toDate()
    : undefined

  const hasDependencies = isDefined(hotelId) && isDefined(marketprice)

  const { data: campaign } = useQueryService(
    'hotel-campaign',
    hasDependencies
      ? [marketprice, cmsLanguage, hotelId, promotionalCoupon]
      : null,
    () =>
      container.resolve('getCampaign')({
        marketprice,
        language: cmsLanguage,
        hotelId,
        promotionalCoupon,
      }),
  )

  const isAvailableCampaign =
    isDefined(campaign) &&
    campaign.bookingStart <= now &&
    now <= campaign.bookingEnd

  const applyCampaign =
    isDefined(campaign) &&
    isDefined(checkInFormatted) &&
    isDefined(checkOutFormatted) &&
    campaign.travelStart <= checkInFormatted &&
    checkOutFormatted <= campaign.travelEnd

  const thereIsOnGoingCampaign = isAvailableCampaign && applyCampaign

  return {
    campaign: thereIsOnGoingCampaign ? campaign : undefined,
  }
}
