import { FC, RefObject } from 'react'
import { Dropdown } from 'src/ui/components'
import { Text } from 'src/ui/components'
import { useCurrency } from 'src/ui/contexts/CurrencyContext'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { currencyFormat } from 'src/ui/views/_components/Shared/currencyFormat'
import styles from './CurrencyDropdown.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  show: boolean
  triggerRef?: RefObject<HTMLButtonElement>
  onClickOutside: () => void
  onSelectCurrency: (currency: string) => void
}

export const CurrencyDropdown: FC<Props> = ({
  show,
  triggerRef,
  onClickOutside,
  onSelectCurrency,
}) => {
  const { currencies } = useCurrency()
  const { trans } = useTrans(['common'])
  const formattedCurrencies = currencyFormat.format(currencies, trans)

  const { queryUtils } = useApplicationRouter()
  const selectedCurrency = queryUtils.getCurrencyParam()

  return (
    <Dropdown
      show={show}
      onClickOutside={onClickOutside}
      triggerRef={triggerRef}
      className={styles.dropdown}
    >
      <ul className={styles.container}>
        {formattedCurrencies.map(currency => (
          <li key={currency}>
            <button
              className={styles.button}
              onClick={() => onSelectCurrency(currency)}
            >
              <Text
                key={currency}
                fontStyle={
                  currencyFormat.getIsoCode(currency) === selectedCurrency
                    ? 'm-700'
                    : 'm-300'
                }
                color="dark"
              >
                {currency}
              </Text>
            </button>
          </li>
        ))}
      </ul>
    </Dropdown>
  )
}
