import { User } from 'src/core/User/domain/User'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'

export const amountOfAppliedCoupons = (
  coupon: AvailabilityCoupon | undefined,
  user?: User,
): number => {
  let amount = 0
  if (isUndefined(coupon) && isUndefined(user)) {
    return 0
  }

  if (isDefined(coupon) && coupon.isGroup() && coupon.applies) {
    return 1
  }

  if (isDefined(user) && isDefined(user.discount)) {
    amount += 1
  }

  if (isDefined(coupon) && coupon.applies) {
    amount += 1
  }

  return amount
}
