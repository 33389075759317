import { FC, useRef, useState } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Media } from 'src/ui/styles/objects/Media'
import { Text } from 'src/ui/components/atoms/Text'

import styles from './CurrencyButton.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useCurrency } from 'src/ui/contexts/CurrencyContext'
import { CurrencyDropdown } from './CurrencyDropdown'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  onSelectCurrency: (currency: string) => void
}

export const CurrencyButton: FC<Props> = ({ onSelectCurrency }) => {
  const { trans } = useTrans(['common'])

  const { queryUtils } = useApplicationRouter()
  const { getCurrencySymbol } = useCurrency()
  const { symbolBefore, symbolAfter } = usePriceFormatter()

  const [showDropdown, setShowDropdown] = useState(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)

  const currencyCode = queryUtils.getCurrencyParam()
  const currencySymbol = getCurrencySymbol(currencyCode)

  const handleOnCurrencyButtonClick = () => {
    setShowDropdown(prev => !prev)
  }

  const handleOnOutsideDropdownClick = () => {
    setShowDropdown(false)
  }

  const handleSelectCurrency = (currency: string) => {
    handleOnOutsideDropdownClick()
    onSelectCurrency(currency)
  }

  return (
    <div
      className={styles.container}
      data-target-desktop-currency-code={currencyCode}
      data-quantum-currency-code={currencyCode}
      data-target-desktop-currency-symbol={currencySymbol}
      data-target-desktop-currency-symbol-position={
        isDefined(symbolBefore) && symbolBefore
          ? 'before'
          : isDefined(symbolAfter) && symbolAfter
          ? 'after'
          : 'unknown'
      }
    >
      <Media laptop desktop>
        <div>
          <button
            className={styles.button}
            onClick={handleOnCurrencyButtonClick}
            ref={triggerDropdownRef}
            aria-label={trans('header_change_currency')}
          >
            <Flex gap="xs">
              <Flex gap="xs">
                <Text fontStyle="m-300" color="light">
                  {currencyCode === currencySymbol
                    ? currencyCode
                    : `${currencyCode} ${currencySymbol}`}
                </Text>
              </Flex>
              <Icon
                icon={ChevronIcon}
                size="l"
                color="icon-light"
                rotate={showDropdown ? 270 : 90}
              />
            </Flex>
          </button>
        </div>
      </Media>

      <CurrencyDropdown
        show={showDropdown}
        triggerRef={triggerDropdownRef}
        onClickOutside={handleOnOutsideDropdownClick}
        onSelectCurrency={handleSelectCurrency}
      />
    </div>
  )
}
