import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Cart, mapReservationIntoCart } from 'src/core/Cart/domain/Cart.model'
import { container } from 'src/core/Shared/_di'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useQueryService } from 'src/ui/hooks/useQuery'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useUser } from './UserContext'
import { Reservation } from 'src/core/Reservation/domain/Reservation.model'
import { useIsTransformingToMemberRates } from 'src/ui/contexts/AuthProvider'

const defaultState = {
  isCartValidating: false,
  cart: undefined,
  isEditableCart: true,
  setEditableCart: () => null,
}

export const CartContext = createContext<{
  isCartValidating: boolean
  cart?: Cart
  isEditableCart: boolean
  setEditableCart: (isEditable: boolean) => void
}>(defaultState)

interface Props {
  children: ReactNode
  onDeletedCart: () => Promise<void>
}

export const CartProvider: FC<Props> = ({ children, onDeletedCart }) => {
  const [removeItineraryNumber, setRemoveItineraryNumber] = useState(false)
  const [cart, setCart] = useState<Cart | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isEditableCart, setEditableCart] = useState<boolean>(true)
  const isTransformingToMemberRates = useIsTransformingToMemberRates()
  const { asPath, queryUtils } = useApplicationRouter()
  const { token } = useUser()

  const itineraryNumber = queryUtils.getRawParam('itineraryNumber')
  const currencyCode = queryUtils.getCurrencyParam()

  const set = (cart: Cart | undefined) => {
    setLoading(false)
    if (isUndefined(cart)) {
      return
    }

    container
      .resolve('sentry')
      .addContextInfo({ id: cart.itineraryNumber }, 'pre-reserve')
    setCart(cart)
  }

  useEffect(() => {
    if (!removeItineraryNumber) {
      return
    }

    const executeRemoveItineraryNumber = async () => {
      setRemoveItineraryNumber(false)
      await onDeletedCart()
    }

    executeRemoveItineraryNumber()
  }, [removeItineraryNumber, setRemoveItineraryNumber, onDeletedCart])

  const deleteCart = useCallback(() => {
    container.resolve('sentry').addContextInfo(undefined, 'pre-reserve')
    setRemoveItineraryNumber(true)
    setCart(undefined)
  }, [])

  const updateCartFromReserve = useCallback(
    (reservation: Reservation | undefined) => {
      if (isUndefined(reservation)) {
        return
      }

      const newCart = mapReservationIntoCart(reservation)
      setCart(newCart)
    },
    [],
  )

  const updateCartFromNewCart = useCallback((newCart: Cart | undefined) => {
    if (isUndefined(newCart)) {
      return
    }

    setCart(newCart)
  }, [])

  useEffect(() => {
    if (isUndefined(itineraryNumber)) {
      setLoading(false)
    }
  }, [itineraryNumber])

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.RESET_PRE_RESERVE, deleteCart)
  }, [deleteCart])

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.UPDATED_PRE_RESERVE_FROM_RESERVE, updateCartFromReserve)
  }, [updateCartFromReserve])

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.UPDATED_PRE_RESERVE_FROM_CART, updateCartFromNewCart)
  }, [updateCartFromNewCart])

  useEffect(() => {
    const unsubscribeStartUpdate = container
      .resolve('eventsManager')
      .on(CustomEvent.START_UPDATE_PRE_RESERVE, () => {
        setLoading(true)
      })

    return () => {
      unsubscribeStartUpdate()
    }
  }, [])

  const { isValidating } = useQueryService(
    'get-cart',
    isDefined(itineraryNumber)
      ? [itineraryNumber, asPath, queryUtils, currencyCode]
      : null,
    () =>
      container.resolve('getCartWhenIsDifferent')(
        itineraryNumber!,
        cart,
        token,
        currencyCode,
      ),
    {
      onSuccess: set,
      revalidateIfStale: true,
    },
  )

  return (
    <CartContext.Provider
      value={{
        isCartValidating:
          isValidating || isLoading || isTransformingToMemberRates,
        cart,
        isEditableCart,
        setEditableCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
