import { FC, RefObject } from 'react'
import { Dropdown } from 'src/ui/components'
import { Text } from 'src/ui/components'
import { useCurrency } from 'src/ui/contexts/CurrencyContext'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useTrans } from 'src/ui/hooks/useTrans'
import styles from './MenuDropdown.module.scss'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'

interface Props {
  show: boolean
  triggerRef?: RefObject<HTMLButtonElement>
  onClickOutside: () => void
  onLanguageClick: () => void
  onCurrencyClick: () => void
}

export const MenuDropdown: FC<Props> = ({
  show,
  triggerRef,
  onClickOutside,
  onLanguageClick,
  onCurrencyClick,
}) => {
  const { trans } = useTrans(['common'])

  const { queryUtils } = useApplicationRouter()
  const { getCurrencySymbol } = useCurrency()

  const languageConfig = useLanguageConfig()
  const { symbolAfter, symbolBefore } = usePriceFormatter()

  const currencyCode = queryUtils.getCurrencyParam()
  const currencySymbol = getCurrencySymbol(currencyCode)

  return (
    <Dropdown
      show={show}
      onClickOutside={onClickOutside}
      triggerRef={triggerRef}
      className={styles.dropdown}
    >
      <ul className={styles.container} data-testid="menu-dropdown">
        <li className={styles.item}>
          <button
            className={styles.button}
            onClick={onLanguageClick}
            aria-label={trans('header_change_language')}
          >
            <Text fontStyle="m-300" color="dark" className={styles.option}>
              {languageConfig.properties.description}
            </Text>
          </button>
        </li>

        <li className={styles.item}>
          <button
            className={styles.button}
            onClick={onCurrencyClick}
            aria-label={trans('header_change_currency')}
            data-target-mobile-currency-code={currencyCode}
            data-target-mobile-currency-symbol={currencySymbol}
            data-target-mobile-currency-symbol-position={
              isDefined(symbolBefore) && symbolBefore
                ? 'before'
                : isDefined(symbolAfter) && symbolAfter
                  ? 'after'
                  : 'unknown'
            }
          >
            <Text fontStyle="m-300" color="dark" className={styles.option}>
              {currencyCode === currencySymbol
                ? currencyCode
                : `${currencyCode} ${currencySymbol}`}
            </Text>
          </button>
        </li>
      </ul>
    </Dropdown>
  )
}
