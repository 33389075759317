import { FC } from 'react'

interface Props {
  color: string
}

export const PhoneIcon: FC<Props> = ({ color }) => (
  <path
    d="M14.4404 20.3072C16.1913 21.4221 18.4816 21.1706 19.9488 19.7022L20.5675 19.0834C20.8444 18.8067 21 18.4312 21 18.0397C21 17.6482 20.8444 17.2727 20.5675 16.9959L17.9579 14.388C17.6812 14.1111 17.3057 13.9555 16.9143 13.9555C16.5228 13.9555 16.1473 14.1111 15.8706 14.388C15.2944 14.9641 14.3605 14.9641 13.7843 14.388L9.61168 10.214C9.03568 9.63776 9.03568 8.70369 9.61168 8.12746C9.88859 7.8507 10.0442 7.47523 10.0442 7.08371C10.0442 6.69218 9.88859 6.31671 9.61168 6.03995L7.00305 3.43204C6.42688 2.85599 5.49291 2.85599 4.91674 3.43204L4.29705 4.05082C2.82914 5.51841 2.57802 7.80897 3.69309 9.5598L3.69899 9.56963C6.55764 13.7995 10.2006 17.4422 14.4306 20.3003L14.4404 20.3072Z"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default PhoneIcon
