import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Currencies } from 'src/core/User/domain/Currency.model'
import { Translator } from 'src/ui/hooks/useTrans'

interface CurrencyFormat {
  format: (currencies: Currencies, trans: Translator) => string[]
  getIsoCode: (currency: string) => string
}

export const currencyFormat: CurrencyFormat = {
  format: (currencies: Currencies, trans: Translator): string[] => {
    if (isUndefined(currencies)) return []

    return Object.entries(currencies).map(currency => {
      const code = currency[0]
      return `${code} - ${trans(`currency-name_${code}`)}`
    })
  },
  getIsoCode: (currency: string): string => {
    return currency.split(' ')[0]
  },
}
