import { FC, useRef, useState } from 'react'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useTrans } from 'src/ui/hooks/useTrans'
import { LanguageModal } from './LanguageModal'
import { CurrencyModal } from './CurrencyModal'
import { MenuDropdown } from './MenuDropdown'
import styles from './MenuButton.module.scss'
import { useModal } from 'src/ui/hooks/useModal'
import { useCurrency } from 'src/ui/contexts/CurrencyContext'
import { currencyFormat } from 'src/ui/views/_components/Shared/currencyFormat'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { MenuAltIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/MenuAltIcon'

interface Props {
  onSelectLanguage: (cbeLanguage: string) => void
  onSelectCurrency: (currency: string) => void
}

export const MenuButton: FC<Props> = ({
  onSelectLanguage,
  onSelectCurrency,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)
  const { trans } = useTrans(['common'])
  const { showModal: showLanguageModal, hideModal: hideLanguageModal } =
    useModal(LanguageModal, undefined, {
      mobile: true,
      tablet: true,
      laptop: false,
      desktop: false,
    })
  const { showModal: showCurrencyModal, hideModal: hideCurrencyModal } =
    useModal(CurrencyModal, undefined, {
      mobile: true,
      tablet: true,
      laptop: false,
      desktop: false,
    })

  const { currencies } = useCurrency()
  const { queryUtils } = useApplicationRouter()

  const languageConfig = useLanguageConfig()
  const selectedLanguage = languageConfig.language

  const handleOnMenuButtonClick = () => {
    setShowDropdown(prev => !prev)
  }

  const handleOnOutsideDropdownClick = () => {
    setShowDropdown(false)
  }

  const handleLanguageClick = () => {
    showLanguageModal({
      languageConfig,
      selectedLanguage,
      onSelectLanguage: handleSelectLanguage,
    })
  }

  const handleCurrencyClick = () => {
    showCurrencyModal({
      currencies: currencyFormat.format(currencies, trans),
      selectedCurrency: queryUtils.getCurrencyParam(),
      onSelectCurrency: handleSelectCurrency,
    })
  }

  const handleSelectLanguage = (cbeLanguage: string) => {
    hideLanguageModal()
    onSelectLanguage(cbeLanguage)
  }

  const handleSelectCurrency = (currency: string) => {
    onSelectCurrency(currency)
    hideCurrencyModal()
  }

  return (
    <>
      <button
        className={styles.button}
        onClick={handleOnMenuButtonClick}
        ref={triggerDropdownRef}
        aria-label={trans('header_menu')}
      >
        <Icon icon={MenuAltIcon} size="l" color="icon-light" />
      </button>
      <MenuDropdown
        show={showDropdown}
        triggerRef={triggerDropdownRef}
        onClickOutside={handleOnOutsideDropdownClick}
        onLanguageClick={handleLanguageClick}
        onCurrencyClick={handleCurrencyClick}
      />
    </>
  )
}
