import { UserLevel } from 'src/core/User/domain/User'
import { Translator } from 'src/ui/hooks/useTrans'

export const getUserLevelText = (level: UserLevel, trans: Translator) => {
  const userLevelTextMap: { [key in UserLevel]: string } = {
    [UserLevel.NotFound]: trans('common:header_auth_my-barcelo-level_start'),
    [UserLevel.Start]: trans('common:header_auth_my-barcelo-level_start'),
    [UserLevel.Intense]: trans('common:header_auth_my-barcelo-level_intense'),
    [UserLevel.Unique]: trans('common:header_auth_my-barcelo-level_unique'),
  }

  return userLevelTextMap[level]
}
