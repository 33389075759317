import { AuthRepository } from 'src/core/User/domain/Auth.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'

export const login =
  ({ authRepository, analytics }: Dependencies) =>
  () => {
    analytics.actions.shared.clickLogin()
    return authRepository.login()
  }

interface Dependencies {
  authRepository: AuthRepository
  analytics: Analytics
}
