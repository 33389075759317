import { Modal, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import styles from './LanguageModal.module.scss'
import { createModal } from 'src/ui/hooks/useModal'
import { Media } from 'src/ui/styles/objects/Media'
import { LanguageConfigState } from 'src/ui/contexts/LanguageConfigContext'

interface Props {
  languageConfig: LanguageConfigState
  selectedLanguage: string
  onSelectLanguage: (cbeLanguage: string) => void
}

export const LanguageModal = createModal(
  ({ languageConfig, selectedLanguage, onSelectLanguage }: Props) => {
    const { trans } = useTrans(['common'])

    return (
      <Media mobile tablet>
        <Modal size="m" type="passive" mobileStyle="fullscreen">
          <Text fontStyle="xl-700" color="dark">
            {trans('header_change_language_title')}
          </Text>
          <ul className={styles.container}>
            {languageConfig.availableLanguages.map(language => (
              <li key={language.cbeLanguage}>
                <button
                  className={styles.button}
                  onClick={() => onSelectLanguage(language.cbeLanguage)}
                >
                  <Text
                    fontStyle={
                      language.cbeLanguage === selectedLanguage
                        ? 'm-700'
                        : 'm-300'
                    }
                    color="dark"
                  >
                    {language.description}
                  </Text>
                </button>
              </li>
            ))}
          </ul>
        </Modal>
      </Media>
    )
  },
)
