import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useMarket } from 'src/ui/contexts/MarketContext'
import { useCurrency } from 'src/ui/contexts/CurrencyContext'
import { useMemo } from 'react'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { buildAvailabilityCriteriaFromPrimitives } from 'src/core/Availability/domain/AvailabilityCriteria'

export const useAvailabilityCriteria = () => {
  const { queryUtils } = useApplicationRouter()
  const { marketPrice } = useMarket()
  const { isCurrencyValid } = useCurrency()

  const { data: availabilityCriteria, errors } = useMemo(() => {
    const { data: availabilityCriteriaParams, errors } =
      queryUtils.getAvailabilityCriteriaParams(isCurrencyValid)

    const availabilityCriteriaPrimitive = isDefined(availabilityCriteriaParams)
      ? {
          ...availabilityCriteriaParams,
          membership: true,
        }
      : undefined

    if (isUndefined(availabilityCriteriaPrimitive)) {
      return {
        data: undefined,
        errors,
      }
    }

    return {
      data: buildAvailabilityCriteriaFromPrimitives(
        availabilityCriteriaPrimitive,
      ),
      errors,
    }
  }, [isCurrencyValid, marketPrice, queryUtils])

  return {
    errors,
    availabilityCriteria,
  }
}
