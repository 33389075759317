import { FC, RefObject } from 'react'
import { Dropdown } from 'src/ui/components'
import { Text } from 'src/ui/components'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import styles from './LanguageDropdown.module.scss'

interface Props {
  show: boolean
  triggerRef?: RefObject<HTMLButtonElement>
  onClickOutside: () => void
  onSelectLanguage: (cbeLanguage: string) => void
}

export const LanguageDropdown: FC<Props> = ({
  show,
  triggerRef,
  onClickOutside,
  onSelectLanguage,
}) => {
  const languageConfig = useLanguageConfig()

  const selectedLanguage = languageConfig.language
  const availableLanguages = languageConfig.availableLanguages

  return (
    <Dropdown
      show={show}
      onClickOutside={onClickOutside}
      triggerRef={triggerRef}
      className={styles.dropdown}
    >
      <ul className={styles.container}>
        {availableLanguages.map(language => (
          <li key={language.cbeLanguage}>
            <button
              className={styles.button}
              onClick={() => onSelectLanguage(language.cbeLanguage)}
            >
              <Text
                fontStyle={
                  language.cbeLanguage === selectedLanguage ? 'm-700' : 'm-300'
                }
                color="dark"
              >
                {language.description}
              </Text>
            </button>
          </li>
        ))}
      </ul>
    </Dropdown>
  )
}
