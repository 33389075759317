import { Button, Modal, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { createModal, useModal } from 'src/ui/hooks/useModal'
import { barceloHome } from 'src/ui/navigation/cmsLinks'
import styles from './ConfirmExitModal.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'

export const ConfirmExitModal = createModal(() => {
  const { hideModal } = useModal()
  const { trans } = useTrans(['new-reservation'])

  return (
    <Modal
      closable={true}
      size="m"
      type="transactional"
      mobileStyle="centered"
      ariaLabel={trans('confirm-exit-modal_aria-label')}
      data-quantum-modal="ConfirmExitModal"
    >
      <Flex direction="column" alignItems="center">
        <Text
          className="mb-m"
          fontStyle={{ mobile: 'xl-700', laptop: '2xl-700' }}
          color="dark"
          as="h1"
          centered
        >
          {trans('confirm-exit-modal_title')}
        </Text>
        <Text as="p" fontStyle="m-300" color="dark" centered className="mb-xl">
          {trans('confirm-exit-modal_description')}
        </Text>
        <Flex className={styles.buttonWrapper}>
          <a href={barceloHome()} className="w-full">
            <Button variant="secondary" type="button">
              {trans('confirm-exit-modal_exit-button')}
            </Button>
          </a>
          <Button onClick={hideModal} type="button">
            {trans('confirm-exit-modal_dismiss-button')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
})
