import { useCallback, useEffect, useRef, useState } from 'react'
import { useMedia } from 'src/ui/hooks/useMedia'

const defaultTop = 0

export const useHideHeaderOnScroll = (): boolean => {
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const lastScrollY = useRef<number>(defaultTop)
  const lastHeight = useRef<number>(defaultTop)
  const { media } = useMedia()

  const handleScroll = useCallback(() => {
    if (media === 'mobile' || media === 'tablet') {
      setIsHidden(false)
      return
    }

    const hasScrolledDown = window.scrollY > lastScrollY.current
    const hasContentHeightChanged =
      document.documentElement.scrollHeight !== lastHeight.current
    setIsHidden(hasScrolledDown || hasContentHeightChanged)

    lastScrollY.current = window.scrollY
    lastHeight.current = document.documentElement.scrollHeight
  }, [media, setIsHidden])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return isHidden
}
