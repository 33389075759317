import { FC, ReactNode } from 'react'
import { UserLevel } from 'src/core/User/domain/User'
import { MyBarceloStartIcon } from 'src/ui/components/atoms/Icon/_icons/general/MyBarceloStartIcon'
import { MyBarceloIntenseIcon } from 'src/ui/components/atoms/Icon/_icons/general/MyBarceloIntenseIcon'
import { MyBarceloUniqueIcon } from 'src/ui/components/atoms/Icon/_icons/general/MyBarceloUniqueIcon'

interface Props {
  width: number
  level?: UserLevel
  variant?: 'dark' | 'light'
  className?: string
  'data-testid'?: string
}

export const MyBarceloLogo: FC<Props> = ({
  width,
  level = UserLevel.Start,
  variant = 'dark',
  className,
  'data-testid': testId,
}) => {
  const fill = `var(--b-color-text-${variant})`

  const iconMap: {
    [key in UserLevel]: ReactNode
  } = {
    [UserLevel.NotFound]: <MyBarceloStartIcon fill={fill} />,
    [UserLevel.Start]: <MyBarceloStartIcon fill={fill} />,
    [UserLevel.Intense]: <MyBarceloIntenseIcon fill={fill} />,
    [UserLevel.Unique]: <MyBarceloUniqueIcon fill={fill} />,
  }

  return (
    <svg
      viewBox="-2 -3 130 35"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      className={className}
      data-testid={testId}
    >
      {iconMap[level]}
    </svg>
  )
}
