import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'
import styles from './PromotionalCouponRibbon.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Text } from 'src/ui/components'
import { Translate } from 'src/ui/i18n/Translate'
import { useCampaign } from 'src/ui/hooks/queries/useCampaign'
import { CampaignModal } from './CampaignModal'
import { useModal } from 'src/ui/hooks/useModal'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

export const PromotionalCouponRibbon: FC<{
  coupon: PromotionalValidCoupon
  isVisible: boolean
  onClose: () => void
}> = ({ coupon, isVisible, onClose }) => {
  const { trans } = useTrans(['new-reservation'])
  const [rootContainer, setRootContainer] = useState<HTMLElement | null>(null)
  const { campaign } = useCampaign()
  const { showModal: showCampaignModal } = useModal(CampaignModal)

  const handleShowCampaignModal = () => {
    if (isUndefined(campaign)) return

    showCampaignModal({ campaign })
  }

  useEffect(() => {
    setRootContainer(document.getElementById('coupon-ribbon-root'))
  }, [])

  if (!isVisible) {
    return null
  }

  /**
   *  El portal es necesario para móvil ya que la frase puede ocupar
   *  varias lineas y usamos la estrategia sticky para que se quede en la parte
   *  superior. Para que el sticky funcione debemos colocar el div al principio
   *  del layout.
   */
  return (
    rootContainer &&
    createPortal(
      <div role="alert" className={styles.textContainer}>
        <Text
          fontStyle={{ mobile: 's-500', tablet: 'm-500' }}
          className={styles.text}
        >
          <Translate
            i18nKey="new-reservation:header_promotional-coupon-ribbon_message"
            values={{
              coupon: coupon.id,
            }}
            components={{
              bold: (
                <Text
                  fontStyle={{ mobile: 's-700', tablet: 'm-700' }}
                  color="dark"
                />
              ),
            }}
          />

          {isDefined(campaign) && (
            <ButtonLink onClick={handleShowCampaignModal} className="ml-s">
              {trans('header_promotional-coupon-ribbon_view-details-button')}
            </ButtonLink>
          )}
        </Text>

        <button onClick={onClose} className={styles.closeButton}>
          <Icon
            size="m"
            color="fill-neutral-04"
            icon={CloseIcon}
            ariaLabel={trans('tooltip_close')}
          />
        </button>
      </div>,
      rootContainer,
    )
  )
}
