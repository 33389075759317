import { FC } from 'react'

interface Props {
  color: string
}

export const LoginIcon: FC<Props> = ({ color }) => (
  <>
    <circle
      cx="12"
      cy="7.9182"
      r="4.9182"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 21.0539C18.8015 17.2876 15.596 14.7695 12 14.7695C8.404 14.7695 5.19852 17.2876 4 21.0539"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default LoginIcon
