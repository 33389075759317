import {
  createContext,
  ReactNode,
  FC,
  useContext,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react'
import {
  Availability,
  AvailabilityCriteria,
  shouldGetAvailabilityWithGroupCode,
} from 'src/core/Availability/domain/Availability.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import {
  AvailabilityError,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { location } from 'src/ui/navigation/location'
import { useCart } from 'src/ui/contexts/CartContext'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { getAvailabilityCriteriaType } from 'src/core/Availability/domain/AvailabilityCriteria'
import { DefaultParamValues } from 'src/ui/hooks/useApplicationRouter/buildQueryUtils'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'

interface AvailabilityContext {
  availability: Availability | undefined
  availabilityCriteria: AvailabilityCriteria | undefined
  error: any | undefined
  isValidating: boolean
  onAvailabilityRef:
    | MutableRefObject<((availability: Availability) => void) | undefined>
    | undefined
}

const _default: AvailabilityContext = {
  availability: undefined,
  availabilityCriteria: undefined,
  error: undefined,
  isValidating: false,
  onAvailabilityRef: undefined,
}

export const AvailabilityContext = createContext<AvailabilityContext>(_default)

interface Props {
  children: ReactNode
}

export const AvailabilityProvider: FC<Props> = ({ children }) => {
  const { availabilityCriteria } = useAvailabilityCriteria()
  const { cart } = useCart()
  const { getRawParams, navigate, query } = useApplicationRouter()
  const onAvailabilityRef = useRef<(availiability?: Availability) => void>()

  const updatedAvailability = async () => {
    const availability = shouldGetAvailabilityWithGroupCode(
      availabilityCriteria,
    )
      ? await container.resolve('getAvailabilityByGroupCoupon')(
          availabilityCriteria,
        )
      : await container.resolve('getAllAvailability')(availabilityCriteria)

    onAvailabilityRef.current?.(availability)

    return availability
  }

  const {
    data: availability,
    error,
    isValidating,
    mutate: getFreshData,
  } = useQueryService<Availability | undefined>(
    `availability-${getAvailabilityCriteriaType(availabilityCriteria)}`,
    [availabilityCriteria, cart],
    updatedAvailability,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      shouldRetryOnError: false,
      overrideOnError: async error => {
        //TODO: Migrar a un gestor de errores en cuanto lo montemos
        const params = getRawParams(['arrive', 'depart', 'currency'])
        if (isUndefined(error)) {
          return
        }

        if (
          error instanceof AvailabilityError &&
          error.type === AvailabilityErrorType.INVALID_HOTEL_ERROR
        ) {
          location.toHotelList(params?.arrive, params?.depart)
          return
        }

        if (
          error instanceof AvailabilityError &&
          error.type === AvailabilityErrorType.CURRENCY_CONVERSION_ERROR &&
          params?.currency !== DefaultParamValues.currency
        ) {
          await navigate.toSameWithReload({
            ...query,
            currency: DefaultParamValues.currency,
          })
        }
      },
    },
  )

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.GET_AVAILABILITY_FRESH_DATA, getFreshData)
  }, [getFreshData])

  return (
    <AvailabilityContext.Provider
      value={{
        availability,
        availabilityCriteria,
        error,
        isValidating,
        onAvailabilityRef,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  )
}

export const useAvailability = () => useContext(AvailabilityContext)
