import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  Availability,
  AvailabilityRate,
} from 'src/core/Availability/domain/Availability.model'

export class AvailabilityCoupon {
  constructor(
    public type: AvailabilityCouponType,
    public value: string,
    public applies?: boolean,
  ) {}

  setNotApplies(): void {
    this.applies = false
  }

  setApplies(): void {
    this.applies = true
  }

  isGroup(): boolean {
    return this.type === 'group'
  }

  isPromotional(): boolean {
    return this.type === 'promotional'
  }

  hasSameValue(couponValue: string): boolean {
    return this.value.toLowerCase() === couponValue.toLowerCase()
  }
}

type AvailabilityCouponType = 'promotional' | 'group'

export const couponAppliesInSomeAvailability = (
  availability: Availability,
  coupon: AvailabilityCoupon,
) => {
  if (coupon.isGroup()) {
    return coupon.hasSameValue(availability.groupCode ?? '')
  }

  return availability.stays.some(stay =>
    stay.rooms.some(room =>
      room.mealplans.some(mealplan =>
        mealplan.rates.some(
          rate =>
            appliesCouponInRate(coupon, rate) ||
            (isDefined(rate.relatedRate) &&
              appliesCouponInRate(coupon, rate.relatedRate.rate)),
        ),
      ),
    ),
  )
}

export const couponAppliesInAllAvailability = (
  availability: Availability,
  coupon: AvailabilityCoupon,
) => {
  if (coupon.isGroup()) {
    return coupon.hasSameValue(availability.groupCode ?? '')
  }

  return availability.stays.every(stay =>
    stay.rooms.every(room =>
      room.mealplans.every(mealplan =>
        mealplan.rates.every(rate => {
          return (
            appliesCouponInRate(coupon, rate) &&
            (isUndefined(rate.relatedRate) ||
              appliesCouponInRate(coupon, rate.relatedRate.rate))
          )
        }),
      ),
    ),
  )
}

export const appliesCouponInRate = (
  coupon: AvailabilityCoupon,
  rate: AvailabilityRate,
) => {
  return coupon.hasSameValue(rate.coupon?.id ?? '') && rate.coupon?.applies
}
